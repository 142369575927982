import React from 'react'

import '../styles/login.css'

const Signup = () => {
  return (
    <div className={"login-container"}>
      <div className="login-card">
        <div className="login-card-background"></div>
      </div>
    </div>
  )
}

export default Signup